<template>
  <div class="bodycont">

    <comHeader />

    <!-- 成果列表展示页面 -->
    <div class="pcont">
      <!-- <div class="rightcont">
        <div  class="news_list"><div class="top_title"><h4 >园企产品</h4></div>
          </div>
      </div> -->

      <div class="leftcont">
        <div class="search">
          <classifyType @getType2='getType2' :gettype='1' @getaName='getaName' />
        </div>
        <!--  <div class="search">
        <div class="demo-input-suffix">
          成果名称：
           <el-input style="width: 200px;"  v-model="form.name"></el-input>
        </div>
        <div class="demo-input-suffix">
         <el-button type="primary">搜索</el-button>
        </div>
      </div> -->
        <div v-if="dataList.length > 0">
          <div class="lcont">
            <div class="continfo">
              <img :src="datainfo.file ? base_url + datainfo.file : nodata" />
              <div class="infobox" @click.stop="tourl(datainfo)">
                <div class="boxtop">
                  <h3>{{ datainfo.aName }}</h3>
                  <!-- <div>
                    <span>成果单位:{{datainfo.achAddress}}</span>
                    <span>成果人:{{datainfo.achName}}</span>
                  </div> -->
                </div>

                <div class="cvinfo">
                  <div class="c_info">
                    <div class="cw100">
                      <div class="w100">
                        <span class="atitle">应用领域:</span>
                        {{ datainfo.application }}
                      </div>
                    </div>
                    <!-- <div class="cw100">
                    <div class="w100">
                      <span class="atitle">学科领域:</span>
                      {{datainfo.subject}}
                    </div>
                  </div> -->
                    <div class="cw100">
                      <div class="w100">
                        <span class="atitle">成熟度:</span>
                        {{ datainfo.maturity }}
                      </div>
                    </div>
                    <div class="cw100">
                      <div class="w100">
                        <span class="atitle">创新性:</span>
                        {{ datainfo.innovation }}
                      </div>
                    </div>
                    <div class="cw100">
                      <div class="w100">
                        <span class="atitle">先进性:</span>
                        {{ datainfo.advanced }}
                      </div>
                    </div>
                    <div class="cw100">
                      <div class="w100">
                        <span class="atitle">浏览次数:</span>
                        {{ datainfo.aVisits || 0 }}次
                      </div>
                    </div>

                  </div>
                  <div class="cr100" v-if="false">
                    <div class="cw100">
                      <span class="atitle">成果概述:</span>
                      <div>{{ datainfo.aOverview }}</div>
                    </div>
                  </div>
                </div>
                <!-- -->

                <!-- <p><span>单位名称:</span>{{datainfo.achAddress || '暂无'}}</p>
              <p><span>地址:</span>{{datainfo.achArea || '暂无'}}</p>
              <p class="infos" style="padding-bottom: 0px;"><span>成果概述:</span>{{datainfo.aoverview || '暂无'}}</p>
              <p class="infos"><span>浏览次数:</span>已有<em style="color: #f00;">{{datainfo.avisits || '0'}}</em>人浏览</p> -->
                <div class="cinfo1">
                  <div class="cdiv">详情>>></div>
                </div>
                <div class="cinfo">
                  <div @click.stop="getnextData(datainfo, 1)" class="cdiv">上一项</div>
                  <div @click.stop="getnextData(datainfo, 2)" class="cdiv">下一项</div>
                </div>

              </div>
              <div class='statusdiv' v-if="datainfo.isConversion">{{ datainfo.isConversion == true ? '已转化' : '' }}</div>
            </div>


          </div>
          <div class="datacont " id='scrollDiv'>
            <div class="linedata" :class="dindex == index ? 'activecur' : ''" v-for="(item, index) in dataList"
              @click="changeData(item, index)">
              <span class="icontext">{{ index + 1 }}</span>
              <span class="iname">{{ item.aName }}</span>
              <span class="inames">发布单位:{{ item.deptName }} | 发布人:{{ item.person }}</span>
              <span class="iarrow">
                <i v-if="dindex != index" class="el-icon-arrow-down"></i>
                <i v-if="dindex == index" class="el-icon-arrow-up"></i>
              </span>
            </div>
          </div>
        </div>
        <div v-if="dataList.length == 0 && isshow">
          <div>
            <div style="margin: 0 auto;width: 100px;">
              <img style="width: 100px;height: 100px;" src="../../assets/images/datano.png" />
            </div>
            <p style="text-align: center;">暂无数据</p>
          </div>

        </div>
        <div class="pages">
          <el-pagination v-if="totalCount > 10 && dataList.length" background layout="prev, pager, next"
            :current-page="page.page" :page-size="page.limit" :total="totalCount" @current-change="handleCurrentChange">
          </el-pagination>
        </div>

      </div>


    </div>


    <bNav />
  </div>

</template>

<script>
import comHeader from '@/components/header.vue'
import bNav from '@/components/bottomNav.vue'
import classifyType from '@/components/classify.vue'
import navTitle from '@/components/navtitle.vue'
import api from '@/config/api'
import {
  getRequst,
  postRequest
} from '@/api/index'
export default {
  components: {
    comHeader,
    bNav,
    classifyType,
    navTitle
  },
  data() {
    return {
      nodata: require('../../assets/images/infonodata.png'),
      form: {},
      cindex: 0,
      navData: [{
        name: '科技成果',
        url: 'tec',
      },],
      datainfo: {

      },
      dataList: [],
      dindex: 0,

      amType: 0,
      amFieldList: '',
      amApplicationList: '',
      amCooperationList: [],
      getAchieveData: [],

      totalCount: 0,
      page: {
        page: 1,
        limit: 20,
      },
      isshow: false,
      base_url: api.base_url,
      aName: '',
    }
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {


    //查询前台成果详情列表
    getList() {
      let data = {
        page: this.page.page,
        limit: this.page.limit,
        amType: 2,
      }
      if (this.aName) {
        data.aName = this.aName
      }

      if (this.amFieldList) {
        data.amFieldList = this.amFieldList
      }
      if (this.amApplicationList) {
        data.amApplicationList = this.amApplicationList
      }

      postRequest(api.getserviceList, data).then(res => {
        if (res.code == 0) {
          this.totalCount = Number(res.page.totalCount)
          this.dataList = res.page.list

          if (this.dataList.length > 0) {
            this.datainfo = this.dataList[0]
          }
          if (this.dataList.length == 0) {
            setTimeout(() => {
              this.isshow = true
            }, 500)
          }

        }
      })
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList()
    },

    //获取关键词搜索
    getaName(data) {
      this.aName = data
      this.dataList = []
      this.page.page = 1
      this.isshow = false
      this.getList()
    },
    //获取学科领域
    getType1(data) {
      if (data) {
        this.amFieldList = data
        this.dataList = []
        this.page.page = 1
        this.getList()
      }

    },
    //获取应用领域
    getType2(data) {
      if (data) {
        this.amApplicationList = data
        this.dataList = []
        this.page.page = 1
        this.getList()
      }

    },
    //查看详情
    tourl(data) {
      this.$router.push({
        path: '/resultDetails',
        query: {
          id: data.aId,
          url: 'parkinfo',
          name: '园企产品',
          type: 'park'
        }
      });

    },
    //点击查看上一条下一条数据
    getnextData(data, type) {
      if (type == 1) {

        if (this.dindex == 0) {
          this.$message.info('已经是第一条数据了!')
        } else {
          this.dataList.forEach((item, index) => {
            if (item.aId == data.aId) {
              console.log(item)
              this.dindex = index - 1
            }
          })
          this.datainfo = this.dataList[this.dindex]
        }
      }
      else {
        if (this.dataList.length - 1 != this.dindex) {
          this.dataList.forEach((item, index) => {
            if (item.aId == data.aId && ((index + 1) >= 0)) {
              this.dindex = index + 1
            }
          })
          this.datainfo = this.dataList[this.dindex]
        } else {
          this.$message.info('暂无更多数据!')
        }
      }

    },
    //切换分类筛选
    changindex(index) {
      this.cindex = index
    },

    //切换选择数据
    changeData(item, index) {
      this.datainfo = item
      this.dindex = index
      this.$forceUpdate()
    }

  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
}

.bodycont {
  // background: url('../../assets/images/homebg1.png') repeat-x;
  width: 100%;
}

.main {
  width: 1190px;
  margin: 0px auto 0;
}

.site_h2 {
  height: 80px;
  width: 350px;
  margin: 0 auto;
  line-height: 80px;
  text-align: center;

  span {
    font-size: 24px;
    padding: 0 15px;
    background: #fff;
  }
}



.cont1 {
  min-height: 300px;
}

.pcont {
  display: flex;
  // justify-content: space-between;
  width: 1190px;
  margin: 20px auto 0;
  font-size: 14px;


  .rightcont {
    width: 200px;
  }

  .leftcont {
    width: 1190px;
    // box-shadow: 0px 5px 14px 0 #ddd;
    // padding: 20px;
    margin: 0 auto;
    min-height: 75vh;

    .lcont {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .continfo {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    background-color: #f0f8fc;
    padding: 20px;
    border-radius: 6px;

    img {
      width: 180px;
      height: 180px;
      border-radius: 10px;
    }

    .infobox {
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      // width: 80%;
      flex:1;
      min-height: 180px;

      .boxtop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 22px;
        font-weight: bold;
        // margin-bottom: 20px;
      }

      p {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;

        span {
          color: #000;
        }
      }

      .infos {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 24px;
      }
    }
  }
}

.cinfo {

  position: absolute;
  right: 20px;
  bottom: 10px;
  display: flex;
  flex-wrap: nowrap;

  .cdiv {
    border: 1px solid #00A0E9;
    padding: 6px;
    color: #00A0E9;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 20px;
  }
}

.cinfo1 {

  position: absolute;
  left: 80px;
  bottom: 10px;
  display: flex;
  flex-wrap: nowrap;

  .cdiv {
    border: 1px solid #00A0E9;
    padding: 6px;
    color: #00A0E9;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
}

.search {
  display: flex;
  justify-content: flex-start;
  ;
  margin-bottom: 20px;

  .demo-input-suffix {
    margin-right: 20px;
  }
}


.news_list {
  width: 200px;
  overflow: hidden;
  float: left;

  .top_title {
    width: 100%;
    height: 54px;
    background: url(../../assets/images/title.png) no-repeat;
    text-align: center;

    h4 {
      color: #fff;
      font-size: 18px;
      line-height: 54px;
    }
  }

  .zczx {
    padding: 0px 0px;
    background: #fff;

    .cur {
      color: #00A0E9;
    }

    li {
      font-size: 16px;
      color: #333;
      background: #eee;
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      margin-bottom: 2px;
      text-align: center;
      padding-left: 0;
      border: none;
      position: relative;
      cursor: pointer;
    }
  }
}

.statusdiv {
  width: 70px;
  background: #00A0E9;
  height: 30px;
  line-height: 30px;
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
}

.linedata {
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-around;

}

.icontext {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #00A0E9;
  color: #fff;
  display: inline-block;
  margin-right: 20px;

}

.iname {
  display: inline-block;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
}

.inames {
  width: 48%;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
}

.activecur {
  background-color: #f0f8fc;
}

.datacont {
  // height: 200px;
  overflow-y: auto;
}

.cvinfo {
  display: flex;

  .c_info {
    display: flex;
    flex-wrap: wrap;
    //width: 60%;
    width:100%;
    padding-right: 30px;

    .cw100 {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding-bottom: 10px;

      .w100 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

      }

      div {
        width: 50%;
        color: #333;
      }
    }

  }

  .cr100 {
    width: 40%;

    .cw100 {
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      color: #333;
    }

  }

  .atitle {
    font-weight: 700;
    padding-bottom: 10px;
    display: inline-block;
    color: #000;
  }
}

.pages {
  margin: 20px auto;
  text-align: center;
}
</style>
